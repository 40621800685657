import { useState } from "react";
import { CardContent, Container, Divider } from "@material-ui/core";
import { Box, Grid, Card } from "@mui/material";
import {
    AddSongModal,
    ArrayChip,
    DatePicker,
    FormLabel,
    Page,
    PrimaryButton,
    SectionLabel,
    SelectInput,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { globalStyles } from "styles";

const FormAdvanceComposer = () => {
    const classes = globalStyles();
    const [selectedSongs, setSelectedSongs] = useState([]);
    const [modalSong, setModalSong] = useState(false);
    const [payload, setPayload] = useState({
        composer_name: "",
        date: null,
        type: "",
        period: null,
    });

    const handleChangePayload = (value, key) => {
        setPayload(currentState => ({
            ...currentState,
            [key]: value,
        }));
    };
    return (
        <Page className={classes?.root} title="Add Advance Transaction">
            <Container maxWidth={false}>
                <HeaderTitle
                    title="Advance Transaction"
                    breadcrumbData={breadcrumbData}
                    backButton
                />
                <Divider className={classes?.divider} />
                <SectionLabel
                    subTitle="Feature for creating song's advanced transaction"
                    my="16px"
                />
                <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" mt="16px">
                    <Grid container columnSpacing={2} rowSpacing={1}>
                        <Grid item xs={12} md={6}>
                            <FormLabel label="Composer Name" />
                            <SelectInput
                                value={payload?.composer_name}
                                onChange={e => handleChangePayload(e?.target?.value, "composer_name")}
                                options={[]}
                                optionKey="id"
                                optionLabel="label"
                                width="100%"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormLabel label="Date" />
                            <DatePicker
                                value={payload?.date}
                                onChange={date => handleChangePayload(date, "date")}
                            />

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormLabel label="Type" />
                            <SelectInput
                                value={payload?.type}
                                onChange={e => handleChangePayload(e?.target?.value, "type")}
                                options={selectionsType}
                                optionKey="id"
                                optionLabel="label"
                                width="100%"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormLabel label="Period" />
                            <DatePicker
                                value={payload?.period}
                                onChange={date => handleChangePayload(date, "period")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems="flex-end"
                                        spacing={2}
                                    >
                                        <Grid item xs={6}>
                                            <SectionLabel title="Contract Composer" />
                                        </Grid>
                                        <Grid item xs={6} justifyContent="flex-end" display="flex">
                                            <PrimaryButton
                                                label="Add Contract"
                                                onClick={() => setModalSong(true)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InnoTable
                                                columns={columnTableSongDetail}
                                                isLoading={false}
                                                isUsingCheckbox={false}
                                                items={selectedSongs || []}
                                                loadingColor={""}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                </Box>
                <Grid container justifyContent="right" spacing={1} mt="16px">
                    <PrimaryButton
                        label="Send to Accounting"
                    />
                </Grid>
            </Container>
            <AddSongModal
                open={modalSong}
                handleClose={val => {
                    setModalSong(false);
                    setSelectedSongs(val);
                }}
                items={selectedSongs}
                body={payload}
                dsp_id={payload.dsp_id}
                startDate={payload.start_date}
                endDate={payload.end_date}
            />
        </Page>
    );
};

const breadcrumbData = [
    {
        label: "Transaction",
        link: "/admin/advance",
    },
    {
        label: "Advance",
        link: "/admin/advance",
    },
    {
        label: "Create Transaction",
        active: true,
    },
];
const selectionsType = [
    { id: "recoupment", label: "Recoupment" },
    { id: "flat", label: "Flat" },
];
const columnTableSongDetail = [
    {
        name: "song_title",
        title: "Song Title",
    },
    {
        name: "composer_names",
        title: "Composer/Author Name",
        renderText: item => <ArrayChip list={item} />,
    },
    {
        name: "composers",
        title: "Alias Name",
        renderText: item => (
            <ArrayChip list={item?.map(composer => composer?.alias_name)} />
        ),
    },
    {
        name: "all",
        title: "Action",
        // renderText: item => <TableAction handleDelete={() => handleDelete(item)} />,
    },
];
export default FormAdvanceComposer;
