import { Chip, makeStyles } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

const mapColor = {
  success: "green",
  danger: "red",
  warning: "orange",
  default: "gray",
};

const useStyles = makeStyles(() => ({
  chip: props => {
    const textColor = props.customColorText || mapColor[props.type] || mapColor.default;
    const backgroundColor =
      props.customColorBackground ||
      (props.type === "success"
        ? "#f0fdf4"
        : props.type === "danger"
          ? "#fef2f2"
          : props.type === "warning"
            ? "#fff7ed"
            : "#f9fafb");

    return {
      borderStyle: "solid",
      borderWidth: props.customBorder?.width || "0.5px",
      borderColor: props.customBorder?.color || textColor,
      color: textColor,
      backgroundColor,
    };
  },
  chipIndicator: props => {
    const textColor = props.customColorText || mapColor[props.type] || mapColor.default;

    return {
      height: 8,
      width: 8,
      color: textColor,
    };
  },
}));

const StatusChip = ({
  label,
  type = "default",
  customColorText,
  customColorBackground,
  customBorder,
}) => {
  const classes = useStyles({ type, customColorText, customColorBackground, customBorder });

  return label ? (
    <Chip
      icon={<FiberManualRecord className={classes.chipIndicator} />}
      label={label}
      size="small"
      className={classes.chip}
    />
  ) : (
    "-"
  );
};

export default StatusChip;
