import { Autocomplete, TextField } from "@mui/material";

const AutoCompleteComponent = ({
  id,
  options,
  label,
  value,
  onChange,
  width = 300,
  changeSearch,
  renderOption,
  loading,
  className,
  size,
  placeholder,
  ...rest
}) => {
  return (
    <Autocomplete
      id={id}
      size={size}
      className={className}
      options={options || []}
      getOptionLabel={option => option.label}
      style={{ width }}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          onChange={changeSearch}
          loading={loading}
          placeholder={placeholder}
        />
      )}
      renderOption={renderOption}
      value={value || null}
      disableClearable={false}
      onChange={(event, newValue) =>
        onChange(newValue ? newValue.id : "", event)
      }
      {...rest}
    />
  );
};

export default AutoCompleteComponent;
