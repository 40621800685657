import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  FormLabel,
  ModalError,
  ModalSuccess,
  MultipleSelectInput,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { globalStyles } from "styles";
import { arrayToCommaSeparatedString, getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const AddOrEditTerritory = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { type, id } = useParams();
  const isEditing = type === "edit" ? true : false;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [optionCountry, setOptionCountry] = useState([]);
  const [state, setState] = useState({
    value: {
      territory_name: "",
      territory_type_flag: "",
      territory_code: "",
      territory_ext_code: "",
      country: [],
    },
    page: 1,
    search: "",
    size: 10,
    listTerritory: [],
    openModal: false,
    totalData: 0,
    loading: false,
    loadingDialog: false,
    openModalDelete: false,
    itemToDelete: null,
  });
  const { value, loading } = state;
  const pageTitle = `${isEditing ? "Edit" : "Add"} Territory`;

  const clearValue = () =>
    setState(currentState => ({
      ...currentState,
      value: {
        territory_name: "",
        territory_type_flag: "",
        territory_code: "",
        territory_ext_code: "",
        country: [],
      },
    }));

  const changeState = (key, value) =>
    setState(currentState => ({
      ...currentState,
      [key]: value,
    }));

  const onChange = (key, value) =>
    setState(currentState => ({
      ...currentState,
      value: {
        ...currentState.value,
        [key]: value,
      },
    }));

  const handleSubmit = async () => {
    const payload = {
      ...value,
      territory_name: value?.territory_name,
      country: value?.country?.map(item => item?.id),
      country_ids: value?.country?.map(item => item?.id),
      description: "",
      is_the_world_flag: false,
      is_usa_flag: false,
      parent_territory_id: 0,
    };

    const method = isEditing ? axios.put : axios.post;
    try {
      changeState("loading", true);
      await method(
        `${hardBaseUrl}/admin/territory${isEditing ? `/${id}` : ""}`,
        payload,
        { headers }
      );
      ModalSuccess(
        `This territory successfully ${isEditing ? "updated" : "added"}`
      ).then(() => history.goBack());
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      changeState("loading", false);
      clearValue();
    }
  };

  const getOptionCountry = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/country/table`, {
        headers,
        params: {
          page: 1,
          per_page: -1,
        },
      });
      setOptionCountry(res?.data?.data || []);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };

  const getDetailEdit = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/admin/territory/${id}`, {
        headers,
      });
      const { data } = res?.data;
      setState(currentState => ({
        ...currentState,
        loadingDialog: false,
        value: {
          ...data,
          country: (data?.territory_countries || [])?.map(
            ({ country_id }) => country_id
          ),
          territory_name: data?.territory_name || "",
          territory_type_flag: data?.territory_type_flag || "",
          territory_code: data?.territory_code || "",
          territory_ext_code: data?.territory_ext_code || "",
        },
      }));
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
    const url = `${hardBaseUrl}/admin/territory/${id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response?.data?.data;
      setState(currentState => ({
        ...currentState,
        loadingDialog: false,
        value: {
          ...data,
          country: (data?.territory_countries || [])?.map(
            ({ country }) => country
          ),
          territory_name: data?.territory_name || "",
          territory_type_flag: data?.territory_type_flag || "",
          territory_code: data?.territory_code || "",
          territory_ext_code: data?.territory_ext_code || "",
        },
      }));
      changeState("loading", false);
    } catch (err) {
      changeState("loading", false);
    }
  };
  const fetchData = async () => {
    changeState("loading", true);
    const promises = [getOptionCountry()];
    if (isEditing) promises.push(getDetailEdit());

    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      changeState("loading", false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page className={classes.root} title={pageTitle}>
      {loading ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={pageTitle}
            breadcrumbData={breadcrumbData({ pageTitle })}
            backButton
          />
          <Divider className={classes.divider} />
          <SectionLabel
            title="Territory Information"
            subTitle="Create data of the territory Information"
          />
          <Box
            border="1px solid #ebebeb"
            borderRadius="5px"
            p="24px"
            my="16px"
            width="60%"
          >
            <Grid container direction="column" rowSpacing={2}>
              {optionTerritory.map(({ key, title }) => (
                <Grid item key={key}>
                  <FormLabel label={title} required />
                  <TextInput
                    disabled={loading}
                    placeholder={title}
                    value={value?.[key]}
                    onChange={e => onChange(key, e?.target?.value)}
                  />
                </Grid>
              ))}
              <Grid item>
                <FormLabel label="Territory Type" required />
                <AutoCompleteComponent
                  options={MockFieldListTerritory}
                  value={
                    MockFieldListTerritory.find(
                      option => option.id === value?.territory_type_flag
                    ) || null
                  }
                  onChange={value => onChange("territory_type_flag", value)}
                  placeholder="Select Territory Type"
                  width="100%"
                  size="small"
                />
              </Grid>
              <Grid item>
                <FormLabel label="Country" required />
                <MultipleSelectInput
                  options={optionCountry}
                  multiple
                  optionKey="id"
                  optionLabel="country_name"
                  value={value?.country || ""}
                  textValue={arrayToCommaSeparatedString(
                    value?.country,
                    "country_name"
                  )}
                  onChange={(event, option) => {
                    const { checked } = event?.target || false;
                    setState(prev => ({
                      ...prev,
                      value: {
                        ...prev.value,
                        country: checked
                          ? [...prev?.value?.country, option]
                          : prev?.value?.country?.filter(
                              item => item?.id !== option?.id
                            ),
                      },
                    }));
                  }}
                  placeholder="Country"
                  width="100%"
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent="right" width="60%">
            <PrimaryButton
              label={loading ? "Saving" : "Save"}
              onClick={() => handleSubmit()}
              disabled={loading}
              loading={loading}
            />
          </Grid>
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = ({ pageTitle }) => [
  {
    label: "Parameter",
    link: "/admin/parameter/territory",
  },
  {
    label: "Territory",
    link: "/admin/parameter/territory",
  },
  {
    label: pageTitle,
    active: true,
  },
];
const optionTerritory = [
  {
    key: "territory_name",
    title: "Territory Name",
  },
  {
    key: "territory_code",
    title: "TIS-A",
  },
  {
    key: "territory_ext_code",
    title: "TIS-A-Ext",
  },
];

const MockFieldListTerritory = [
  {
    label: "Country",
    value: "Country",
    id: "Country",
  },
  {
    label: "Economical Country-G",
    value: "Economical",
    id: "Economical",
  },
  {
    label: "Geographical Country",
    value: "Geographical",
    id: "Geographical",
  },
  {
    label: "Political Country-Gro",
    value: "Political",
    id: "Political",
  },
];

export default AddOrEditTerritory;
