import { Breadcrumbs, Typography } from "@mui/material";
import { useHistory } from "react-router";

const InnoBreadcrumbs = ({ data }) => {
  const history = useHistory();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {data?.map((value, key) => {
        return (
          <a
            key={key}
            onClick={() => history.push(value.link)}
            href={value?.link}
          >
            <Typography
              fontSize={14}
              fontWeight={400}
              color={value.active ? "#687083" : "#111827"}
            >
              {value.label}
            </Typography>
          </a>
        );
      })}
    </Breadcrumbs>
  );
};

export default InnoBreadcrumbs;
