import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: "10px 28px 10px 28px",
    backgroundColor: "black",
    color: "white",
    width: "213px",
    height: "40px",
    borderRadius: "6px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  selectPadding: {
    height: "80px",
    "& .MuiInputBase-fullWidth": {
      height: "55px",
    },
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",

    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  mt6: {
    marginTop: "6px",
  },
  textSearch: {
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    width: "266px",
  },
  textStyle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#687083",
    marginTop: "24px",
  },
  containerDiv: {
    marginTop: "16px",
    border: "1px solid #D1D5DC",
    borderRadius: "10px",
    padding: "16px",
    width: "340px",
    backgroundColor: "#111827",
    position: "relative",
  },
  flex: {
    display: "flex",
  },
  divAction: {
    marginTop: "20px",
    gap: "4px",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const DefaultTresshold = () => {
  const classes = useStyles();

  const [tresshold, setTresshold] = useState(0);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleChangeTresshold = event => {
    if (event.target.name === "tresshold") {
      setTresshold(event.target.value);
    }
  };

  const handleSaveTresshold = async () => {
    setLoadingButton(true);
    const url = `${hardBaseUrl}/publisher/cost-allocation/tresshold`;
    const token = localStorage.getItem("token");

    const payload = {
      tresshold: Number(tresshold),
    };

    await axios
      .post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setLoadingButton(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Tresshold has been updated successfully",
          confirmButtonText: "Ok",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch(err => {
        setLoadingButton(false);
        Swal.fire({
          title: "Error",
          text: err.response.data.errors[0].message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const getTresshold = () => {
    const url = `${hardBaseUrl}/publisher/cost-allocation/tresshold`;
    const token = localStorage.getItem("token");

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setTresshold(res.data.data.tresshold);
      })
      .catch(err => {
        Swal.fire({
          title: "Error",
          text: err.response.data.errors[0].message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    getTresshold();
  }, []);

  return (
    <Page className={classes.root} title="Default Thresshold">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.font24}>
                Cost Allocation
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Cost Allocation
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Default Tresshold
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
            <ThemeProvider theme={theme}>
              <Typography variant="h3" className={classes.subtitle}>
                Default Tresshold
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p className={classes.textStyle}>
                Feature to set default tresshold for cost allocation
              </p>
            </ThemeProvider>

            <div>
              <Grid container spacing={1}>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <Card>
                    <CardContent>
                      <ThemeProvider theme={theme}>
                        <label>Tresshold</label>
                      </ThemeProvider>

                      <TextField
                        defaultValue={0}
                        variant="outlined"
                        onChange={e => handleChangeTresshold(e)}
                        value={tresshold}
                        type="number"
                        name="tresshold"
                        id="formatted-numberformat-input"
                        margin="dense"
                        fullWidth={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {"%"}
                            </InputAdornment>
                          ),
                        }}
                      />

                      <div className={classes.divAction}>
                        <PrimaryButton
                          label="Save"
                          onClick={handleSaveTresshold}
                          loading={loadingButton}
                          disabled={loadingButton}
                        />
                        <PrimaryButton
                          onClick={() => window.history.back()}
                          label="Back"
                          loading={loadingButton}
                          disabled={loadingButton}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}></Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DefaultTresshold;
