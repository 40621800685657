import { Divider } from "@material-ui/core";
import { Box, Container, Grid } from "@mui/material";
import { hardBaseUrl } from "services/urlConstant";
import axios from "axios";
import {
  AlertSnackbar,
  FormLabel,
  ModalError,
  ModalSuccess,
  MultipleSelectInputWithTags,
  Page,
  PasswordInput,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { globalStyles } from "styles";
import { arrayToCommaSeparatedString, getErrors, stringTruncate } from "utils";
import { useSelector } from "react-redux";

const FormParameterUser = ({ userLogin }) => {
  const classes = globalStyles();
  const history = useHistory();
  const { id } = useParams();
  const typeWeb = localStorage.getItem("typeWeb");
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const pageTitle = `${id ? "Edit" : "Add"} User`;
  const isMpis = typeWeb === "mpis";
  const urlEndpoints = `${hardBaseUrl}/${isMpis ? "" : "publisher/"}user${
    id ? `/${id}` : ""
  }`;
  const roleAdmin = userLogin?.role?.type === "admin";

  const publisherId = useSelector(state => state.publisherId);
  const [loadingPage, setLoadingPage] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [optionRole, setOptionRole] = useState([]);
  const [filteredOptionRole, setFilteredOptionRole] = useState([]);
  const [searchRole, setSearchRole] = useState("");
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    phone: "",
    role_ids: [],
    ...(!id && { password: "" }),
  });
  const { name, email, phone, role_ids, password } = payload;
  const [initialPayload, setInitialPayload] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChangeQueryParams = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleSelectRole = (event, option) => {
    const { checked } = event?.target || false;
    setPayload(prev => ({
      ...prev,
      role_ids: checked
        ? [...prev?.role_ids, option]
        : prev?.role_ids?.filter(item => item?.role_id !== option?.role_id),
    }));
  };
  const validatePayload = () => {
    const errors = [];
    const errorEmpty = err => `${err} can't be empty.`;

    Object.entries(payload).forEach(([key, value]) => {
      switch (key) {
        case "name":
          if (!value) errors.push(errorEmpty("Name"));
          break;
        case "role_ids":
          if (!value.length) errors.push(errorEmpty("Role"));
          break;
        case "email":
          if (!value) errors.push(errorEmpty("Email"));
          break;
        case "phone":
          if (!value) errors.push(errorEmpty("Phone"));
          break;
        case "password":
          if (!value && !id) errors.push(errorEmpty("Password"));
          break;
        default:
          break;
      }
    });

    return errors;
  };

  const getOptionRole = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/role`, {
        headers,
        params: {
          type: roleAdmin ? "admin" : "publisher",
        },
      });
      const { data } = res?.data;
      setOptionRole(data || []);
      setFilteredOptionRole(data || []);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      if (!id) setLoadingPage(false);
    }
  };
  const getDetail = async () => {
    try {
      const res = await axios.get(urlEndpoints, {
        headers,
      });
      const { name, email, phone, role_ids } = res?.data?.data;
      const preload = {
        name,
        email,
        phone,
        role_ids: role_ids.map(
          roleId => optionRole.find(option => option.role_id === roleId) || {}
        ),
      };
      setPayload(preload);
      setInitialPayload(preload);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSubmit = async () => {
    const method = id ? axios.put : axios.post;
    const errors = validatePayload();
    if (errors?.length) {
      setAlertMessage(errors[0]);
      setAlertVisible(true);
      return;
    }
    const modifiedPayload = {
      ...payload,
      role_ids: payload?.role_ids?.map(item => item?.role_id),
      ...(!id && { publisher_id: publisherId.publisherId }),
    };
    try {
      setLoadingPage(true);
      await method(urlEndpoints, modifiedPayload, {
        headers,
      });
      ModalSuccess(`Successfully ${id ? "update" : "save"} user`).then(() =>
        history.goBack()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getOptionRole();
  }, []);
  useEffect(() => {
    if (optionRole?.length > 0 && id) getDetail();
  }, [optionRole]);
  useEffect(() => {
    setFilteredOptionRole(
      searchRole
        ? optionRole?.filter(role =>
            role?.name?.toLowerCase().includes(searchRole?.toLowerCase())
          )
        : optionRole
    );
  }, [searchRole]);
  useEffect(() => {
    const hasEmptyFields =
      !name || !email || !phone || !role_ids.length || (!id && !password);
    const hasChanged =
      id && JSON.stringify(payload) !== JSON.stringify(initialPayload);
    setButtonDisabled(Boolean(hasEmptyFields || (id && !hasChanged)));
  }, [payload, initialPayload]);

  return (
    <Page className={classes.root} title={pageTitle}>
      <AlertSnackbar
        open={alertVisible}
        message={alertMessage}
        onClose={() => setAlertVisible(false)}
      />
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={pageTitle}
            breadcrumbData={breadcrumbData({ pageTitle })}
            backButton
          />
          <Divider className={classes?.divider} />
          <SectionLabel
            title="User Information"
            subTitle={`${pageTitle}'s Data`}
          />
          <Box width="70%">
            <Box
              border="1px solid #ebebeb"
              borderRadius="5px"
              p="24px"
              my="16px"
            >
              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Full Name" required />
                  <TextInput
                    placeholder="Full Name"
                    value={payload?.name}
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "name")
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Role Name" required />
                  <MultipleSelectInputWithTags
                    options={filteredOptionRole}
                    placeholder="Choose Role"
                    value={payload?.role_ids}
                    textValue={stringTruncate(
                      arrayToCommaSeparatedString(payload?.role_ids, "name"),
                      40
                    )}
                    optionKey="role_id"
                    optionLabel="name"
                    onChange={handleSelectRole}
                    search
                    searchValue={searchRole}
                    onChangeSearch={setSearchRole}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Email" required />
                  <TextInput
                    placeholder="Email"
                    value={payload?.email}
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "email")
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Phone Number" required />
                  <TextInput
                    placeholder="Phone Number"
                    value={payload?.phone}
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "phone")
                    }
                    type="number"
                  />
                </Grid>
                {!id && (
                  <Grid item xs={12} md={6}>
                    <FormLabel label="Password" required />
                    <PasswordInput
                      placeholder="Password"
                      value={payload?.password}
                      onChange={e =>
                        handleChangeQueryParams(e?.target?.value, "password")
                      }
                      helperText="Minimum 8 Characters"
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
            <Grid container justifyContent="right">
              <PrimaryButton
                label={
                  loadingPage
                    ? id
                      ? "Updating"
                      : "Saving"
                    : id
                    ? "Update"
                    : "Save"
                }
                onClick={handleSubmit}
                disabled={buttonDisabled || loadingPage}
                loading={loadingPage}
              />
            </Grid>
          </Box>
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = ({ pageTitle }) => [
  {
    label: "Parameter",
    link: "/admin/parameter/user",
  },
  {
    label: "User",
    link: "/admin/parameter/user",
  },
  {
    label: pageTitle,
    active: true,
  },
];
export default FormParameterUser;
