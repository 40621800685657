import { Add } from "@material-ui/icons";
import { Box, Grid } from "@mui/material";
import {
  CustomTable,
  DatePicker,
  FormLabel,
  ModalAddContractSong,
  ModalError,
  NumberInput,
  PrimaryButton,
  SecondaryButton,
  SectionLabel,
  SupportingDocumentForm,
  TableCellMultipleRow,
  TextInput,
} from "components";
import moment from "moment-timezone";
import { useEffect, useState } from "react";

const ContractOriginalPublisherForm = ({ preload, handlSubmitData }) => {
  const buttonCaption = preload ? "Update Contract" : "Add Contract";
  const [modalSongVisiblity, setModalSongVisiblity] = useState(false);
  const [selectedSongTable, setSelectedSongTable] = useState([]);
  const [supportingDocumentFiles, setSupportingDocumentFiles] = useState([]);
  const [payload, setPayload] = useState({
    contract_number: "",
    date_periode: 1,
    type_periode: "days",
    start_date: moment(),
    end_date: moment(),
    description: "",
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangePeriodType = value => {
    handleChangePayload(value, "type_periode");
    if (value === "days") {
      handleChangePayload(
        changeDate(payload?.start_date, payload?.date_periode),
        "end_date"
      );
    } else {
      handleChangePayload(
        changeYear(payload?.start_date, payload?.date_periode),
        "end_date"
      );
    }
  };
  const handleChangeTimePeriod = event => {
    const value = parseInt(event?.target?.value) || 0;
    handleChangePayload(value, "date_periode");
    if (payload?.type_periode === "days") {
      handleChangePayload(changeDate(payload?.start_date, value), "end_date");
    } else {
      handleChangePayload(changeYear(payload?.start_date, value), "end_date");
    }
  };
  const handleAddSong = song => {
    const selectedId = song?.song_id;
    const isSongSelected = selectedSongTable.some(
      item => item.song_id === selectedId
    );
    if (isSongSelected) {
      setSelectedSongTable(prev =>
        prev.filter(item => item.song_id !== selectedId)
      );
    } else {
      setSelectedSongTable(prev => [...prev, song]);
    }
  };
  const validatePayload = () => {
    const errors = [];
    const errorEmpty = err => `${err} can't be empty.`;

    Object.entries(payload).forEach(([key, value]) => {
      switch (key) {
        case "contract_number":
          if (!value) errors.push(errorEmpty("Contract Number"));
          break;
        case "start_date":
          if (!value) errors.push(errorEmpty("Start Date"));
          break;
        case "end_date":
          if (!value) errors.push(errorEmpty("End Date"));
          break;
        default:
          break;
      }
    });
    if (selectedSongTable?.length === 0) {
      errors.push(errorEmpty("Song"));
    }

    return errors;
  };

  const getPreload = () => {
    const { contract, songs } = preload;
    setPayload({
      type_periode: contract?.type_periode || "days",
      contract_number: contract?.contract_number,
      date_periode: contract?.date_periode,
      start_date: contract?.start_date
        ? setPreloadDate(contract?.start_date)
        : moment(),
      end_date: contract?.end_date
        ? setPreloadDate(contract?.end_date)
        : moment(),
      description: contract?.description,
    });
    if (songs) {
      const modifiedData = songs?.map(item => ({
        ...item,
        title_song: item?.title,
        song_composer: item?.composer?.map(composer => ({
          ...composer,
          sure_name: composer?.composer_name,
        })),
      }));
      setSelectedSongTable(modifiedData);
    }
  };
  const handleSubmit = () => {
    const errors = validatePayload();
    if (errors?.length) {
      ModalError(errors[0]);
      return;
    }
    const modifiedPayload = {
      ...payload,
      song_ids: selectedSongTable?.map(song => song?.song_id),
      start_date: setPayloadDate(payload?.start_date),
      ...(payload?.date_periode !== 0 && {
        end_date: setPayloadDate(payload?.end_date),
      }),
    };
    handlSubmitData({ modifiedPayload, supportingDocumentFiles });
  };
  useEffect(() => {
    if (preload) {
      getPreload();
    }
  }, [preload]);

  return (
    <Box>
      <Grid container direction="column" rowSpacing={2}>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <SectionLabel
                title="Song"
                subTitle="This feature can add multiple songs in one contract"
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Add Song"
                startIcon={<Add />}
                onClick={() => setModalSongVisiblity(true)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <SectionLabel
            title="Composer/Author"
            subTitle="Composer/Author Information"
            mb="16px"
          />
          <CustomTable data={selectedSongTable} columnTable={columnTable} />
        </Grid>
        <Grid item>
          <SectionLabel
            title="Information Contract"
            subTitle="Form to fill out information data related to the contract to be made"
            mb="16px"
          />
          <Grid container columnSpacing={1}>
            {buttonPeriod?.map(item =>
              payload?.type_periode === item?.value ? (
                <Grid item key={item?.value}>
                  <PrimaryButton
                    label={item?.label}
                    onClick={() => handleChangePeriodType(item?.value)}
                    width={100}
                  />
                </Grid>
              ) : (
                <Grid item key={item?.value}>
                  <SecondaryButton
                    label={item?.label}
                    onClick={() => handleChangePeriodType(item?.value)}
                    width={100}
                  />
                </Grid>
              )
            )}
          </Grid>
          <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" mt="16px">
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormLabel label="Contract Number" required />
                <TextInput
                  value={payload?.contract_number}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "contract_number")
                  }
                  placeholder="Contract Number"
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel label="Time Period" required />
                <NumberInput
                  placeholder={0}
                  onChange={value => handleChangeTimePeriod(value)}
                  endAdornment={
                    payload?.type_periode === "days" ? "Days" : "Years"
                  }
                  value={payload?.date_periode}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel label="Start Date" required />
                <DatePicker
                  value={payload?.start_date}
                  onChange={date => {
                    handleChangePayload(date, "start_date");
                    if (payload?.type_periode === "days") {
                      handleChangePayload(
                        changeDate(date, Number(payload?.date_periode)),
                        "end_date"
                      );
                    } else {
                      handleChangePayload(
                        changeYear(date, Number(payload?.date_periode)),
                        "end_date"
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel label="End Date" required />
                <DatePicker disabled value={payload?.end_date} />
              </Grid>
              <Grid item xs={12}>
                <FormLabel label="Description" />
                <TextInput
                  value={payload?.description}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "description")
                  }
                  placeholder="Description"
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {!preload && (
          <Grid item>
            <SectionLabel
              title="Supporting Document"
              subTitle="Upload supporting documents, such as scanned agreement documents or can be in the form of pdf files"
              mb="16px"
            />
            <SupportingDocumentForm
              supportingDocumentFiles={supportingDocumentFiles}
              setSupportingDocumentFiles={setSupportingDocumentFiles}
            />
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="right" my="16px">
        <PrimaryButton
          label={buttonCaption}
          onClick={handleSubmit}
          size="large"
        />
      </Grid>
      <ModalAddContractSong
        visiblity={modalSongVisiblity}
        handleClose={() => setModalSongVisiblity(false)}
        selectedSong={selectedSongTable}
        rowClick={handleAddSong}
        endPoint="/publisher/song"
      />
    </Box>
  );
};

const changeDate = (init, value) => moment(init).add(value, "days");
const changeYear = (init, value) => moment(init).add(value, "years");
const setPayloadDate = date => (date ? moment(date).format("YYYY-MM-DD") : "");
const setPreloadDate = date => (date ? moment(date, "DD-MM-YYYY") : "");
const columnTable = [
  {
    name: "title_song",
    title: "Song",
    renderText: item => item || "-",
  },
  {
    name: "song_composer",
    title: "Composer/Author Name",
    renderText: item => (
      <TableCellMultipleRow
        list={item?.map(composer => ({
          sure_name: composer?.composer?.sure_name,
        }))}
        itemKey="sure_name"
        type="chip"
      />
    ),
  },
  {
    name: "iswc_code",
    title: "ISWC Code",
    renderText: item => item || "-",
  },
  {
    name: "song_composer",
    title: "Ownership Percentage",
    renderText: item => (
      <TableCellMultipleRow
        list={item?.map(composer => ({
          percentage_ownership: composer?.composer?.percentage_ownership,
          sure_name: composer?.composer?.sure_name,
        }))}
        itemKey="percentage_ownership"
        type="number"
        suffix=" %"
        tooltipKey="sure_name"
      />
    ),
  },
];
const buttonPeriod = [
  { value: "days", label: "Day" },
  { value: "year", label: "Year" },
];

export default ContractOriginalPublisherForm;
