import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  ArrayTagChip,
  AutoCompleteComponent,
  CurrencyDisplay,
  CustomTable,
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
  YearPicker,
} from "components";
import { HeaderTitle } from "layouts";
import moment from "moment";
import randomColor from "randomcolor";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import ChartStacked from "./ChartStacked";
import ChartStackedPerc from "./ChartStackedPerc";
import { getTotalPage } from "lib";
import { debounce } from "lodash";

function DashboardAssoc() {
  const history = useHistory();
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [chartType, setChartType] = useState("stacked_number");
  const [loadingPage, setLoadingPage] = useState(false);
  const [optionDSP, setOptionDSP] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    dsp_id: "",
    type: "revenue",
    year: moment().year(),
    page: 1,
    size: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const calculateTotalSums = (data, revenueType) => {
    const totalSums = [];
    const length = data[0]?.chart?.length || 0;

    for (let i = 0; i < length; i++) {
      const sum = data.reduce((acc, item) => {
        const value =
          revenueType === "usage"
            ? item.chart[i].traffic
            : item.chart[i].base_currency_revenue;
        return acc + (value || 0);
      }, 0);
      totalSums.push(sum);
    }

    return totalSums;
  };
  const calculatePercentage = (value, total) => {
    if (value > 0 && total > 0) {
      return parseFloat(((value / total) * 100).toFixed(2));
    }
    return 0;
  };
  const createDatasets = (data, revenueType, chartType) => {
    return data.map(item => {
      const dataValues = item.chart.map(data => {
        const value =
          revenueType === "usage" ? data.traffic : data.base_currency_revenue;
        return chartType === "stacked"
          ? value
          : calculatePercentage(value, data.total_inner);
      });

      return {
        label: item.publisher_name,
        dataListener: dataValues,
        data: dataValues,
        color: item?.color,
        borderColor: item?.color,
        backgroundColor: item?.color,
        pointBackgroundColor: item?.color,
        pointBorderColor: item?.color,
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: item?.color,
        fill: false,
        lineTension: 0.2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      };
    });
  };

  const getOptionDsp = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/association/dsps`, {
        headers,
      });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.dsp_id,
        label: item?.name,
      }));
      setOptionDSP(modifiedData);
    } catch (error) {
      getErrors(error?.response);
    } finally {
      setLoadingPage(false);
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/dashboard/report/publisher`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      if (data?.length > 0) {
        const labels = data[0].chart.map(item =>
          moment(item.date).format("MMM YY")
        );
        const modifiedData = data?.map(item => ({
          ...item,
          color: randomColor(),
        }));
        setDataTable(modifiedData || []);
        const totalSums = calculateTotalSums(modifiedData, queryParams?.type);
        const processedData = modifiedData.map(item => {
          const newChart = item.chart.map((data, index) => ({
            ...data,
            total_inner: totalSums[index],
          }));

          return { ...item, chart: newChart };
        });

        setChartData({
          labels,
          datasets: createDatasets(processedData, queryParams?.type, chartType),
        });
        const pageCount = getTotalPage(meta?.total, queryParams?.size);
        setTableTotalPage(pageCount || 1);
      } else {
        setChartData({
          labels: [],
          datasets: [],
        });
      }
    } catch (error) {
      getErrors(error?.response);
    } finally {
      setLoadingPage(false);
    }
  };
  useEffect(() => {
    getOptionDsp();
  }, []);

  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, chartType, debounceDataTable]);

  return (
    <Page className={classes.root} title="Dashboard Association">
      <Container maxWidth={false}>
        <HeaderTitle title="Dashboard" breadcrumbData={breadcrumbData} />
        <Divider className={classes.divider} />
        <Grid container justifyContent="left" columnSpacing={1} mb="24px">
          <Grid item>
            <SelectInput
              value={chartType}
              onChange={e => setChartType(e?.target?.value)}
              options={optionChartType}
              optionKey="id"
              optionLabel="name"
              width={200}
            />
          </Grid>
          <Grid item>
            <AutoCompleteComponent
              options={optionDSP}
              label="DSP"
              value={
                optionDSP.find(option => option.id === queryParams?.dsp_id) ||
                null
              }
              onChange={id => handleChangeQueryParams(id, "dsp_id")}
              width={200}
              size="small"
            />
          </Grid>
          <Grid item>
            <SelectInput
              value={queryParams?.type}
              onChange={e => handleChangeQueryParams(e?.target?.value, "type")}
              options={optionRevenueType}
              optionKey="id"
              optionLabel="name"
              width={150}
            />
          </Grid>
          <Grid item>
            <YearPicker
              label="Year"
              onChange={year => handleChangeQueryParams(year, "year")}
              value={queryParams?.year}
              width={150}
            />
          </Grid>
        </Grid>
        <Box border="1px solid #ebebeb" borderRadius="5px" p="24px 24px 50px">
          <Box mb="24px">
            <ArrayTagChip
              selectionsList={dataTable.map(dsp => ({
                name: dsp?.publisher_name,
                color: dsp?.color,
              }))}
              value="name"
            />
          </Box>
          {chartType === "stacked" ? (
            <ChartStacked data={chartData} />
          ) : (
            <ChartStackedPerc data={chartData} />
          )}
        </Box>
        <Divider className={classes.divider} />
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <>
            <SearchTextInput
              fullWidth
              placeholder="Search Publisher"
              value={queryParams?.search}
              onChange={e => {
                handleChangeQueryParams(e?.target?.value, "search");
              }}
            />
            <Divider className={classes?.divider} />
            <CustomTable
              columnTable={columnTable}
              data={dataTable}
              isHaveAction
              renderAction={item => (
                <PrimaryButton
                  label="See Details"
                  onClick={() =>
                    history.push(
                      `/admin/review-lagu-association?dsp_id=${queryParams?.dsp_id}&publisher_id=${item?.publisher_id}&year=${queryParams?.year}`
                    )
                  }
                  width={110}
                />
              )}
              page={queryParams?.page}
              pageSize={queryParams?.size}
              pageCount={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangePageSize={e =>
                handleChangeQueryParams(e?.target?.value, "size")
              }
            />
          </>
        )}
      </Container>
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard-association",
  },
  {
    label: "Dashboard",
    active: true,
  },
];
const optionChartType = [
  { id: "stacked_number", name: "Stacked Bar 100%" },
  { id: "stacked", name: "Stacked Bar" },
];
const optionRevenueType = [
  { id: "revenue", name: "Revenue" },
  { id: "usage", name: "Traffic" },
];
const columnTable = [
  {
    name: "color",
    title: "",
    renderText: item => <Box bgcolor={item} height={20} width={20} />,
  },
  {
    name: "publisher_name",
    title: "Publisher",
    renderText: item => item || "-",
  },
  {
    name: "traffic",
    title: "Traffic",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "base_currency_revenue",
    title: "Revenue",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} prefix="Rp " />,
  },
  {
    name: "advance_revenue",
    title: "Advance DSP",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} prefix="Rp " />,
  },
];
export default DashboardAssoc;
