import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar as BarPerc } from "react-chartjs-2";

ChartJS.register(ChartDataLabels);
ChartJS.register(...registerables);

const options = {
  responsive: true,
  parsing: {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    delimiter: ",",
    complete: function() {},
  },
  plugins: {
    datalabels: {
      formatter: (value, context) => {
        const returnValue =
          context?.chart?.data?.datasets?.[context?.datasetIndex]
            ?.dataListener?.[context?.dataIndex];
        return typeof returnValue === "number" && returnValue !== 0
          ? `${returnValue} %`
          : "";
      },
      color: context => {
        const backgroundColor =
          context.chart.data.datasets[context.datasetIndex].backgroundColor;
        const color = Array.isArray(backgroundColor)
          ? backgroundColor[context.dataIndex]
          : backgroundColor;
        const hexToRgb = hex => {
          const bigint = parseInt(hex.slice(1), 16);
          const r = (bigint >> 16) & 255;
          const g = (bigint >> 8) & 255;
          const b = bigint & 255;
          return [r, g, b];
        };
        const rgb = color.startsWith("#")
          ? hexToRgb(color)
          : color
              .replace(/^rgba?\(|\s+|\)$/g, "")
              .split(",")
              .map(Number);
        const luminance = 0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2];

        return luminance > 128 ? "black" : "white";
      },
    },
    legend: {
      display: false,
    },
    tooltip: {
      mode: "index",
      intersect: false,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      titleFont: {
        size: 14,
        weight: "bold",
      },
      bodyFont: {
        size: 12,
      },
      bodySpacing: 6,
      padding: 12,
      boxPadding: 4,
      borderColor: "#FFFFFF",
      borderWidth: 1,
      cornerRadius: 8,

      callbacks: {
        label: tooltipItem => {
          const dataset = tooltipItem.dataset;
          const value = dataset?.data?.[tooltipItem?.dataIndex];
          return `${dataset?.label}: ${value ? `${value} %` : "-"}`;
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  dialogPaper: {
    width: "420px",
    maxWidth: "420px",
  },
}));
function ChartStackedPerc(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.fixedHeightPaper}>
      <BarPerc options={options} data={props.data} plugins={[]} />
    </Paper>
  );
}

export default ChartStackedPerc;
