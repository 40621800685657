import { Container, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, Typography } from "@mui/material";
import {
    CurrencyDisplay,
    FormLabel,
    Page,
    PrimaryButton,
    SectionLabel,
    SmallChip,
} from "components";
import html2canvas from "html2canvas";
import { InnoImage, InnoTableV2 } from "inno-ui";
import { jsPDF } from "jspdf";
import { HeaderTitle } from "layouts";
import { useRef } from "react";
import { textCapitalization } from "utils";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    results: {
        marginTop: theme.spacing(3),
    },
    divider: {
        margin: theme.spacing(2, 0),
        borderTop: "1px solid #e1e1e1",
    },
    formControl: {},
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    pagination: {
        "& .Mui-selected": {
            backgroundColor: "black",
            color: "white",
        },
    },
    pageSize: {
        height: "30px",
        borderRadius: "6px",
        border: "1px solid #D1D5DC",
        paddingLeft: "5px",
        paddingRight: "5px",
        marginTop: "5px",
    },
    paginationText: {
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        color: "#687083",
    },
    breadcrumbs: {
        fontSize: "14px",
        fontWeight: 400,
        fontStyle: "regular",
        color: "#111827",
    },
    breadCrumbsActive: {
        fontSize: "14px",
        fontWeight: 400,
        fontStyle: "regular",
        color: "#687083",
    },
    placeholder: {
        "&::placeholder": {
            color: "#9AA2B1",
        },
    },
    subTitle: {
        fontSize: "18px",
        fontWeight: 700,
        color: " #111827",
    },
    headText: {
        fontSize: "12px",
        color: "#687083",
        fontWeight: "bold",
    },
    font24: {
        fontSize: "24px",
        fontWeight: "700",
        lineHeight: "32px",
    },
    textStyle: {
        marginTop: "5px",
        color: "#8f8f8f",
        fontSize: "14px",
    },
    grid1: {
        textAlign: "right",
        display: "flex",
        justifyContent: "flex-end",
        flex: 1,
    },
    chip1: {
        border: "1px solid #F1A69E",
        height: "28px",
        color: "#A63124",
        backgroundColor: "#FCF3F2",
    },
    card1: {
        border: "1px solid #9AA2B1",
        borderRadius: "6px",
        boxShadow: "none",
    },
    styleImage: {
        maxWidth: "155.52px",
        width: "100%",
        height: "100%",
        maxHeight: "50px",
        objectFit: "contain",
    },
    font14: {
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
    },
    font14Color: {
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "17px",
        color: "#6F6F84",
        wordWrap: "break-word",
    },
    flexBetween: {
        display: "flex",
        justifyContent: "space-between",
    },
    mt5: {
        marginTop: "5px",
    },
    mt20: {
        marginTop: "20px",
    },
    border1: {
        border: "1px solid #9AA2B1",
        borderRadius: "18px",
    },
    bgF9FAFB: {
        backgroundColor: "#F9FAFB",
    },
    flexCenter: {
        display: "flex",
        justifyContent: "center",
    },
    textRight: {
        textAlign: "right",
    },
    divAction: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "end",
    },
}));

const DetailAdvancedFinance = () => {
    const classes = useStyles();
    const inputRef = useRef(null);

    const handlePrintPDF = () => {
        html2canvas(inputRef.current, { useCORS: true }).then(canvas => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "px", "a4");
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
            pdf.save("download.pdf");
        });
    };

    return (
        <Page className={classes.root} title="Advance Transaction">
            <Container maxWidth={false}>
                <HeaderTitle
                    title="Advance Transaction"
                    breadcrumbData={breadcrumbData}
                    backButton
                />
                <Divider className={classes.divider} />
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <SectionLabel
                                    title="Detail Credit Note"
                                    subTitle="This is detail credit note information, in advance transaction"
                                />
                            </Grid>
                            <Grid className={classes.grid1} item>
                                <SmallChip
                                    label={textCapitalization("paid")}
                                    type="success"
                                />
                            </Grid>
                        </Grid>
                        <Box
                            ref={inputRef}
                            border="1px solid #9AA2B1"
                            padding="24px"
                            borderRadius="8px"
                            my="16px"
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <InnoImage
                                        // src={pageDetail?.publisher?.profile_image}
                                        alt="img"
                                        fill={true}
                                        styleImage={{
                                            maxWidth: "155.52px",
                                            width: "100%",
                                            height: "100%",
                                            maxHeight: "50px",
                                            objectFit: "contain",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        textAlign="right"
                                        fontSize={14}
                                        fontWeight={600}
                                    >
                                        CARIS MUSIC ENTERTAINMENT
                                    </Typography>
                                    <Typography textAlign="right" fontSize={14} color="#6F6F84">
                                        Jl. Setia Budi, RT 05 RW 05, Karet Kuningan, South Jakarta
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <FormLabel label="Advance Composer :" />
                                    <FormLabel label="Composer Name A" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormLabel label="Transaction Number :" />
                                    <FormLabel label="USG/2024/November/12/105543" />
                                    <FormLabel label="Type :" />
                                    <FormLabel
                                        label={textCapitalization("flat") || "-"}
                                    />
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <FormLabel label="Contract Number" />
                            <InnoTableV2
                                isLoading={false}
                                columns={columnTable}
                                items={[]}
                            />
                            <Grid container justifyContent="right" mt="16px">
                                <Grid item>
                                    <Typography>Recoupment Total :</Typography>
                                    <Typography textAlign="right">
                                        <CurrencyDisplay
                                            value={10000000}
                                            decimalScale={2}
                                        // prefix={`${currencyPrefix} `}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container justifyContent="right">
                            <PrimaryButton
                                onClick={handlePrintPDF}
                                label="Download As PDF"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};
const breadcrumbData = [
    {
        label: "Home",
        link: "/admin/dashboard",
    },
    {
        label: "Transaction",
        link: "/admin/advance",
    },
    {
        label: "Advance Transaction",
        link: "/admin/advance",
    },
    {
        label: "Detail Transaction",
        active: true,
    },
];
const columnTable = [
    {
        name: "song_title",
        title: "Contract Number",
    },
    {
        name: "composer_names",
        title: "Song Title",
    },
    {
        name: "traffic",
        title: "Advance Value",
    },
    {
        name: "revenue",
        title: "Recoupement",
    },
    {
        name: "",
        title: "Remaining Balance",
    },
    {
        name: "",
        title: "Start Period",
    },
    {
        name: "",
        title: "End Period",
    },
];

export default DetailAdvancedFinance;
